import styled from 'styled-components'

import { Section } from 'components/general-styles'
import { theme } from 'styles/theme'

const { borderWidths, colors, fontSizes, sizes, media, radii, space } = theme

export const Solutions = styled(Section)`
  width: 100%;
  background-color: ${colors['neutral-background-default']};

  > div {
    gap: ${space['spacing-md']};
  }
`

export const TextWrapper = styled.div`
  display: flex;
  height: 100%;

  br {
    display: none;
  }

  @media (${media.desktop}) {
    br {
      display: block;
    }

    h2 {
      font-size: ${fontSizes['font-size-heading-2']};
      
      span {
        font-size: ${fontSizes['font-size-heading-2']};
      }
    }
  }
`

export const SolutionsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: ${space['spacing-sm']};

  @media (${media.desktop}) {
    grid-template-columns: repeat(4, 1fr);
  }
`

export const Option = styled.div`
  display: grid;
  padding: ${space['spacing-sm']};
  gap: ${space['spacing-sm']};
  border: ${borderWidths['border-stroke-micro']} solid ${colors['neutral-border-default']};
  border-radius: ${radii['corner-radius-sm']};

  a {
    font-size: ${fontSizes['font-size-body-2']};
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      width: ${sizes['spacing-md']};
      height: ${sizes['spacing-md']};
    }
  }

  @media (${media.tablet}) {
    flex-basis: 30%;
    min-height: 10rem;
    padding: ${space['spacing-md']};
    grid-template-rows: 60px 60px 25px;
  }
`

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.25rem;
  height: 3.25rem;
  background-color: ${colors['neutral-background-hover']};
  border-radius: ${radii['corner-radius-circular']};
`
