import { memo } from 'react'

import { Icon, Text, Link } from '@cash-me/react-components'

import { Grid, Col } from 'components/grid'

import * as S from './styles'

const Solutions = memo(() => (
  <S.Solutions>
    <Grid col={{ phone: 1, tablet: 4, desktop: 12 }}>
      <Col start={{ desktop: 2 }} col={{ tablet: 1, desktop: 10 }}>
        <S.TextWrapper>
          <Text as="h2" size="heading3" weight="medium">
            A CashMe {' '}
            <Text as="span" size="heading3" color="var(--colors-pink-text-default)" weight="medium">
              tem soluções {' '}
            </Text>
            que impulsionam seus objetivos
          </Text>
        </S.TextWrapper>
      </Col>
      <Col start={{ desktop: 2 }} col={{ tablet: 4, desktop: 10 }}>
        <S.SolutionsWrapper>
          <S.Option>
            <S.IconWrapper>
              <Icon name="smartphone" size={28} color="var(--colors-cyan-icon-default)" />
            </S.IconWrapper>
            <Text>Empréstimo Online</Text>
            <Link
              className="anchor"
              href="/emprestimo-online/"
              id="link"
              size="default"
            >
              Saiba mais
              <Icon
                name="arrowRight"
                size={12}
              />
            </Link>
          </S.Option>
          <S.Option>
            <S.IconWrapper>
              <Icon name="moneyCircleDash" size={28} color="var(--colors-cyan-icon-default)" />
            </S.IconWrapper>
            <Text>Empréstimo Rápido</Text>
            <Link
              className="anchor"
              href="/emprestimo-rapido/"
              id="link"
              size="default"
            >
              Saiba mais
              <Icon
                name="arrowRight"
                size={12}
              />
            </Link>
          </S.Option>
          <S.Option>
            <S.IconWrapper>
              <Icon name="home" size={28} color="var(--colors-cyan-icon-default)" />
            </S.IconWrapper>
            <Text>Home Equity</Text>
            <Link
              className="anchor"
              href="/home-equity/"
              id="link"
              size="default"
            >
              Saiba mais
              <Icon
                name="arrowRight"
                size={12}
              />
            </Link>
          </S.Option>
          <S.Option>
            <S.IconWrapper>
              <Icon name="person" size={28} color="var(--colors-cyan-icon-default)" />
            </S.IconWrapper>
            <Text>Empréstimo para Autônomo</Text>
            <Link
              className="anchor"
              href="/emprestimo-para-autonomo/"
              id="link"
              size="default"
            >
              Saiba mais
              <Icon
                name="arrowRight"
                size={12}
              />
            </Link>
          </S.Option>
          <S.Option>
            <S.IconWrapper>
              <Icon name="brush" size={28} color="var(--colors-cyan-icon-default)" />
            </S.IconWrapper>
            <Text>Empréstimo para Reforma</Text>
            <Link
              className="anchor"
              href="/emprestimo-para-reforma/"
              id="link"
              size="default"
            >
              Saiba mais
              <Icon
                name="arrowRight"
                size={12}
              />
            </Link>
          </S.Option>
          <S.Option>
            <S.IconWrapper>
              <Icon name="loan" size={28} color="var(--colors-cyan-icon-default)" />
            </S.IconWrapper>
            <Text>Portabilidade de Dívidas</Text>
            <Link
              className="anchor"
              href="/portabilidade-de-dividas/"
              id="link"
              size="default"
            >
              Saiba mais
              <Icon
                name="arrowRight"
                size={12}
              />
            </Link>
          </S.Option>
          <S.Option>
            <S.IconWrapper>
              <Icon name="shop" size={28} color="var(--colors-cyan-icon-default)" />
            </S.IconWrapper>
            <Text>Empréstimo para Empresa</Text>
            <Link
              className="anchor"
              href="/emprestimo-para-empresa/"
              id="link"
              size="default"
            >
              Saiba mais
              <Icon
                name="arrowRight"
                size={12}
              />
            </Link>
          </S.Option>
          <S.Option>
            <S.IconWrapper>
              <Icon name="pencil" size={28} color="var(--colors-cyan-icon-default)" />
            </S.IconWrapper>
            <Text>Simulação para Empréstimo</Text>
            <Link
              className="anchor"
              href="/simulacao-de-emprestimo/"
              id="link"
              size="default"
            >
              Saiba mais
              <Icon
                name="arrowRight"
                size={12}
              />
            </Link>
          </S.Option>
        </S.SolutionsWrapper>
      </Col>
    </Grid>
  </S.Solutions>
))

Solutions.displayName = 'Solutions'

export default Solutions
